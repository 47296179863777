<template>
  <div class="page"
       :class="isMobile?'mobile':'pc'">
    <div :class="isMobile?'head':'pc_head'">
      <div class="title">
        <p> {{type_str === 'secret' ? '隐私政策' : '用户协议'}} </p>
      </div>
    </div>

    <div class="v_content"
         v-html="agreement_str"></div>

    <div v-for="( item,i) in content_str_list"
         :key="i">
      <div class="med">
        <div v-if="item.title!==''"
             class="light_title">{{item.title}}</div>
        <p v-for="( content,j) in item.lis"
           :key="j"
           class="li">
          {{content.content}}
        </p>
      </div>
    </div>
    <ul v-if="isMobile"  id="last_right_b" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>

  </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import Utils from '@/utils/utils'

export default {
  name: 'agreement',
  data () {
    return {
      niming: store.state.niming,
      isMobile: Utils.is_mobile(),
      content_str_list: [],
      type_str: ''
    }
  },
  mounted () {
    this.type_str = this.$route.query.type
    this.get_index()
  },
  methods: {
    get_index () {
      axios.post(this.niming ? '/get_agreement_unlogin/' : '/get_agreement/', { type: this.type_str })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.content_str_list = res.data.data.content_str
        })
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>
.pc {
  width: 1024px;
  margin: 0px auto;
  background: #f8f8f8;
}
.pc_head {
  height: 200px;
  font-size: 80px;
  line-height: 200px;
  background-image: linear-gradient(rgb(70, 130, 180), #81D3F8);
  color: #fefefe;
}
.pc .med {
  padding: 10px 0px;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  margin: 20px 0px;
}
.pc .med .light_title {
  background: rgba(37, 130, 173, 0.2);
  color: #fefefe;
  width: 500px;
  display: inline-block;
  padding: 10px 40px;
  border-radius: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 25px;
  margin: 20px 0px;
  text-align: left;
}

.mobile .med {
  padding: 0.2rem 0.16rem;
  font-size: 0.36rem;
}
.mobile .med .li {
  font-size: 0.36rem;
  padding: 0rem 0.5rem;
}
.mobile .med .light_title {
  width: 8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  margin: 0.20rem auto 0.40rem auto;
  text-align: center;
  display: block;
  background: rgba(37, 130, 173, 0.2);
  border-radius: 2rem;
  color: #fefefe;
}
</style>
